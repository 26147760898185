const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');

const ICON_ID = 'tooltip';
const namespace = 'ui-pdp-icon ui-pdp-icon--tooltip';

const IconTooltipInfo = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconTooltipInfo.propTypes = {
  className: string,
};

IconTooltipInfo.defaultProps = {
  className: null,
};

module.exports = React.memo(IconTooltipInfo);
exports = module.exports;
exports.ICON_ID = ICON_ID;
